import _ from "lodash";
import { useTranslation } from "react-i18next";
const news = require("../../src/data/news.json");

export const Section8 = () => {
  const newsData = _.cloneDeep(news);
  const { t } = useTranslation();

  return (
    <div className="w-screen h-full">
      <div className="section-container py-10">
        <div>
          <div className="font-bold text-3xl">{t("News")}</div>
        </div>
        <div className="grid md:grid-cols-3 self-center gap-8 mt-8">
          {newsData
            .sort(
              (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
            )
            .map((i, idx) => {
              return (
                <div
                  className="w-full cursor-pointer py-2"
                  key={`section8-${idx}`}
                >
                  <div className="text-center self-center w-full relative bg-black">
                    <img
                      src={`/assets/news/${i.banner}`}
                      alt="banner"
                      className="w-[500px] h-[300px] mx-auto text-center self-center object-cover"
                    />
                  </div>
                  <div className="px-1 mt-2">
                    <div className="text-sm text-[#5A5A5A] italic">
                      {new Date(i.time).toLocaleDateString()}
                    </div>
                    <div className="text-lg mt-2 font-bold">
                      {t(`news_title_${i.id}`)}
                    </div>
                    <div className="text-md mt-1">{t(`news_desc_${i.id}`)}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
