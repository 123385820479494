import { useTranslation } from "react-i18next";

const Card = ({ title, description, icon }) => {
  return (
    <div className="w-full sm:w-[290px] card-container rounded-lg font-raleway bg-white">
      <div className="self-center grid grid-cols-3 p-4">
        <div>
          <img src={icon} alt="icon" className="w-12 h-12" />
        </div>
        <div className="col-span-2">
          <div className="font-bold text-xl">{title}</div>
          <div>{description}</div>
        </div>
      </div>
    </div>
  );
};

export const Section3 = () => {
  const { t } = useTranslation();

  return (
    <div className="w-screen h-full relative bg-white">
      <div className="relative">
        <div className="grid md:grid-cols-2 relative">
          <div className="bg-black text-white self-center py-10 pb-20 md:py-20 section-container">
            <div className="text-3xl font-bold">{t("about us")}</div>
            <div className="w-full mt-6 text-xl">{t("about_us_desc")}</div>
          </div>
          <div className="w-full h-full relative">
            <img
              src="/assets/banner/homepage-11.webp"
              className="w-full h-full object-cover absolute"
              alt="banner"
            />
          </div>

          <div></div>
        </div>
        <div
          className="section-container flex flex-wrap gap-8 justify-start bg-black md:bg-transparent pb-10"
          style={{
            transform: "translateY(-40px)",
          }}
        >
          <Card
            title={t("annual revenue")}
            description="~2 millions dollars"
            icon="/assets/icon/revenue.webp"
          />
          <Card
            title={t("work force")}
            description={`120+ ${t("headcount")}`}
            icon="/assets/icon/workforce.webp"
          />
          <Card
            title={t("stateoftheart")}
            description={`50+ ${t("modern machines")}`}
            icon="/assets/icon/stateoftheart.webp"
          />
        </div>
        <div className="section-container">
          <div className="grid md:grid-cols-2 justify-between gap-10 relative">
            <div className="font-raleway">
              <div className="text-3xl font-bold">{t("Certification")}</div>
              <div className="mt-10">+ ISO 9001:2015</div>
              <div className="mt-2">
                + {t("Guhring Authorized Distributor")}
              </div>
              <img
                className="mt-10"
                src="/assets/image/certificate.webp"
                alt="certificate"
              />
            </div>
            <div className="h-min">
              <img
                src="/assets/image/authorized.webp"
                alt="certificate"
                className="max-h-[89vh] object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
