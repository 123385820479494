import { useTranslation } from "react-i18next";
import { Dropdown, Flag } from "semantic-ui-react";
import i18n from "i18n";
export default function Language({ language, setLanguage }) {
  const { t } = useTranslation();
  function changeLanguage(lng) {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  }
  return (
    <Dropdown
      trigger={
        <span className="text-white flex gap-2 relative pr-4 sm:pr-0 sm:pt-0 pt-1.5 sm:translate-y-[2.5px]">
          <div className="font-raleway capitalize w-max">{t("language")}</div>
          <div>
            {language === "vi" && <Flag name="vn" />}
            {language === "en" && <Flag name="england" />}
            {language === "ja" && <Flag name="jp" />}
          </div>
        </span>
      }
      direction="left"
      pointing
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            changeLanguage("vi");
          }}
          content={
            <p>
              <Flag name="vn" /> {t("Vietnamese")}
            </p>
          }
        />
        <Dropdown.Item
          onClick={() => {
            changeLanguage("en");
          }}
          content={
            <p>
              <Flag name="england" /> {t("English")}
            </p>
          }
        />
        <Dropdown.Item
          onClick={() => {
            changeLanguage("ja");
          }}
          content={
            <p>
              <Flag name="jp" /> {t("Japanese")}
            </p>
          }
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}
