import { Element } from "react-scroll";
import { useEffect } from "react";
import { Section1 } from "components/Section1";
import { Section2 } from "components/Section2";
import { Section3 } from "components/Section3";
import { Section4 } from "components/Section4";
import { Section5 } from "components/Section5";
import { Section6 } from "components/Section6";
import { Section7 } from "components/Section7";
import { Section8 } from "components/Section8";

export default function Main(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main
        style={{
          minHeight: "100vh",
        }}
        className={`flex flex-col ${props.selectedMap !== -1 && "bg-black-mb"}`}
      >
        <Element name="banner" id="section1">
          <Section1 />
        </Element>
        <Element name="product" id="section2">
          <Section2 />
        </Element>
        <Element name="aboutus" id="section3">
          <Section3 />
        </Element>
        <Element name="corevalue" id="section4">
          <Section4 language={props.language} />
        </Element>
        <Element name="milestone" id="section5">
          <Section5 />
        </Element>
        <Element name="partner" id="section6">
          <Section6 />
        </Element>
        <Element name="vision" id="section7">
          <Section7 />
        </Element>
        <Element name="news" id="section8">
          <Section8 />
        </Element>
      </main>
    </>
  );
}
