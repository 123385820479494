import { Link } from "react-scroll";
import { useState, useRef, useEffect } from "react";
import Language from "components/Language";
import { useTranslation } from "react-i18next";
var Scroll = require("react-scroll");
var Events = Scroll.Events;
export default function Header(props) {
  const [optical, setOptical] = useState(0);
  const [elements, setElements] = useState([]);
  const [scrolling, setScrolling] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const refMenuContent = useRef();
  const { t } = useTranslation();

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(refMenuContent, () => {
    process.nextTick(() => {
      if (!showMenu) {
        return;
      }
      setShowMenu(false);
    });
  });

  function changeOptical(index) {
    console.log({ scrolling, index });
    if (scrolling) {
      return;
    }
    let section;
    switch (index) {
      case 0:
        break;
      case 1:
        section = document.getElementById("section2");
        if (section) {
          window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: section.offsetTop - 80,
          });
        }
        break;
      case 2:
        section = document.getElementById("section3");
        if (section) {
          window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: section.offsetTop - 80,
          });
        }
        break;
      case 3:
        section = document.getElementById("section7");
        if (section) {
          window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: section.offsetTop - 80,
          });
        }
        break;
      case 4:
        section = document.getElementById("section8");
        if (section) {
          window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: section.offsetTop - 80,
          });
        }
        break;
      default:
        break;
    }
    setOptical(index);
  }
  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      setScrolling(true);
    });

    Events.scrollEvent.register("end", function (to, element) {
      setScrolling(false);
    });
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <div
      className="flex flex-row fixed w-full py-1 pb-2 sm:px-10 lg:px-20 bg-black -top-[5px]"
      style={{ zIndex: 100 }}
    >
      <div className="hidden md:flex justify-between">
        <div className="self-center">
          <img
            src="/assets/header/logo-large.webp"
            alt="logo"
            className="hidden lg:block h-[48px] object-contain"
          />
          <img
            src="/assets/header/logo-small.webp"
            alt="logo"
            className="block lg:hidden h-[42px] object-contain"
          />
        </div>
        <div className="flex-row items-center gap-8 bg-headerbg bg-contain bg-no-repeat bg-center w-screen text-center z-50 px-8">
          <div
            ref={(ref) => {
              if (!ref) {
                return;
              }
              let els = [];
              ref.childNodes.forEach((i) => {
                if (i.localName !== "a") {
                  return;
                }
                els.push({
                  width: i.clientWidth,
                  pos: i.offsetLeft,
                });
                if (elements.length) {
                  if (els[0].width > elements[0].width) {
                    setElements(els);
                  }
                } else {
                  setElements(els);
                }
              });
            }}
            className="flex flex-row text-lg font-bold gap-4 bg-clip-text items-center select-none relative mt-2 justify-end"
            style={{ marginRight: "8rem" }}
          >
            <Link
              className="header-item hover"
              href="#"
              to="banner"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => {
                changeOptical(0);
              }}
            >
              <p className="font-raleway leading-6 text-white cursor-pointer react-button uppercase">
                {t("home")}
              </p>
            </Link>
            <Link
              to="#"
              className="header-item hover"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => {
                changeOptical(1);
              }}
              href="#"
            >
              <p className="font-raleway leading-6 text-white cursor-pointer react-button uppercase">
                {t("product")}
              </p>
            </Link>
            <Link
              to="#"
              className="header-item hover"
              href="#"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                changeOptical(2);
              }}
            >
              <p className="font-raleway leading-6 text-white cursor-pointer react-button uppercase">
                {t("about")}
              </p>
            </Link>
            <Link
              className="header-item hover"
              href="#"
              to="teammember"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                changeOptical(3);
              }}
            >
              <p className="font-raleway leading-6 text-white cursor-pointer react-button uppercase">
                {t("vision")}
              </p>
            </Link>
            <Link
              className="header-item hover"
              href="#"
              to="teammember"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                changeOptical(4);
              }}
            >
              <p className="font-raleway leading-6 text-white cursor-pointer react-button uppercase">
                {t("News")}
              </p>
            </Link>
            <Language
              language={props.language}
              setLanguage={props.setLanguage}
            />
          </div>
        </div>
      </div>
      <div className="flex md:hidden relative flex-row items-center justify-center w-screen bg-cover bg-center">
        <div className="dropdown absolute flex top-0 left-0 justify-center items-center">
          {!showMenu && (
            <img
              className="ml-3"
              src="/assets/header/menu.svg"
              alt="menu"
              onClick={() => {
                if (showMenu) return;
                setShowMenu(true);
              }}
            />
          )}
          {showMenu && (
            <div
              ref={refMenuContent}
              className="dropdown-content w-screen h-screen"
              style={{
                background:
                  "radial-gradient(50% 50% at 50% 50%, rgba(20, 20, 20, 0.85) 0%, #202020 100%)",
              }}
            >
              <div className="flex flex-col items-center">
                <div
                  className="text-white absolute right-10 text-2xl cursor-pointer"
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  X
                </div>
                <div className="flex flex-col text-lg font-bold gap-2 w-full select-none mt-10">
                  <Link
                    to="banner"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                    href="#"
                  >
                    <p className="font-raleway leading-6 mb-2 mt-4 text-md bg-clip-text  text-white font-raleway from-tab1 via-tab2 to-tab1 cursor-pointer uppercase">
                      {t("home")}
                    </p>
                  </Link>
                  <div
                    className="h-[1px] w-full "
                    style={{ backgroundColor: "#50481E" }}
                  />
                  <Link
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                    onClick={() => {
                      changeOptical(1);
                    }}
                    href="#"
                  >
                    <p className="font-raleway leading-6 my-2 text-md bg-clip-text  text-white font-raleway from-tab1 via-tab2 to-tab1 cursor-pointer uppercase">
                      {t("product")}
                    </p>
                  </Link>
                  <div
                    className="h-[1px] w-full "
                    style={{ backgroundColor: "#50481E" }}
                  />
                  <Link
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={() => {
                      changeOptical(2);
                    }}
                    href="#"
                  >
                    <p className="font-raleway leading-6 my-2 text-md bg-clip-text  text-white font-raleway from-tab1 via-tab2 to-tab1 cursor-pointer uppercase">
                      {t("about")}
                    </p>
                  </Link>
                  <div
                    className="h-[1px] w-full "
                    style={{ backgroundColor: "#50481E" }}
                  />
                  <Link
                    to="teammember"
                    spy={true}
                    smooth={true}
                    offset={-60}
                    duration={500}
                    onClick={() => {
                      changeOptical(3);
                    }}
                    href="#"
                  >
                    <p className="font-raleway leading-6 my-2 text-md bg-clip-text  text-white font-raleway from-tab1 via-tab2 to-tab1 cursor-pointer uppercase">
                      {t("vision")}
                    </p>
                  </Link>
                  <div
                    className="h-[1px] w-full "
                    style={{ backgroundColor: "#50481E" }}
                  />
                  <Link
                    to="teammember"
                    spy={true}
                    smooth={true}
                    offset={-60}
                    duration={500}
                    onClick={() => {
                      changeOptical(4);
                    }}
                    href="#"
                  >
                    <p className="font-raleway leading-6 my-2 text-md bg-clip-text  text-white font-raleway from-tab1 via-tab2 to-tab1 cursor-pointer uppercase">
                      {t("News")}
                    </p>
                  </Link>
                  <div
                    className="h-[1px] w-full "
                    style={{ backgroundColor: "#50481E" }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="top-[10px] right-0 flex items-center justify-center">
        {!showMenu && (
          <Language language={props.language} setLanguage={props.setLanguage} />
        )}
      </div>
    </div>
  );
}
