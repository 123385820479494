import _ from "lodash";
import { useTranslation } from "react-i18next";
const milestones = require("../../src/data/milestones.json");

export const Section5 = () => {
  const milestonesData = _.cloneDeep(milestones);
  const { t } = useTranslation();

  return (
    <div className="w-screen h-full pb-10 md:pb-0 md:h-screen bg-milestone bg-white">
      <div className="section-container py-6 md:py-12">
        <div className="text-3xl font-bold">{t("Important Milestones")}</div>
        <div className="relative w-full mt-4">
          {milestonesData.map((milestones, idx) => {
            let isLast = idx === milestonesData.length - 1;
            let isLeft = idx % 2 !== 0;
            let mileStoneComponent = (
              <div
                className={`milestone-container-${
                  isLeft ? "left" : "right"
                } absolute`}
              >
                <div className="bg-[#3C3C3C] py-2 text-white ">
                  {milestones.time}
                </div>
                <div className="bg-white text-lg xl:text-xl max-w-full w-[200px] py-4">
                  {t(milestones.title)}
                </div>
              </div>
            );

            return (
              <>
                {/* for pc tablet */}
                <div
                  className="hidden md:block text-center mx-auto self-center relative"
                  key={`section5-${idx}`}
                >
                  <div
                    className={`absolute flex mx-auto ${
                      isLeft ? "left-link" : "right-link"
                    }`}
                    style={{
                      top: "30px",
                    }}
                  >
                    {!isLeft && mileStoneComponent}
                    <img
                      src={`/assets/icon/milestone-link-${
                        isLeft ? "right" : "left"
                      }.png`}
                      alt="link"
                      className="w-full h-full"
                    />
                    {isLeft && mileStoneComponent}
                  </div>
                  <img
                    src={`/assets/icon/${milestones.icon}`}
                    alt="icon"
                    className="mx-auto"
                  />
                  {!isLast && <div className="link-verticle mx-auto"></div>}
                </div>

                {/* for mobile */}
                <div className="block md:hidden text-center mx-auto self-center relative">
                  <div
                    className={`mt-10 flex mx-auto relative w-full self-center justify-center`}
                    style={{
                      top: "30px",
                    }}
                  >
                    <div className={"z-20"}>
                      <div className="bg-[#3C3C3C] py-2 text-white">
                        {milestones.time}
                      </div>
                      <div className="bg-white text-sm w-[200px] py-4">
                        {t(milestones.title)}
                      </div>
                    </div>
                    {!isLast && (
                      <div className="h-[100px] absolute w-[2px] translate-y-[40px] text-center bg-black mx-auto self-center justify-center"></div>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
