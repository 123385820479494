import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";

const handleDragStart = (e) => e.preventDefault();

const items = [
  <img
    src="/assets/image/com-iki.webp"
    onDragStart={handleDragStart}
    role="presentation"
    alt="iki"
  />,
  <img
    src="/assets/image/com-tanloviet.webp"
    onDragStart={handleDragStart}
    role="presentation"
    alt="tanloviet"
  />,
  <img
    src="/assets/image/com-tanviet.webp"
    onDragStart={handleDragStart}
    role="presentation"
    alt="tanviet"
  />,
];

export const Section6 = () => {
  const { t } = useTranslation();
  return (
    <div className="w-screen h-full bg-black">
      <div className="section-container py-14">
        <div className="text-white text-3xl font-bold">
          <div className="text-center">{t("Our Partners")}</div>
          <div className="mt-10 self-center gap-10 flex flex-wrap mx-center text-center justify-center partner">
            <img
              src="/assets/image/com-iki.webp"
              onDragStart={handleDragStart}
              role="presentation"
              alt="iki"
              className="w-full max-w-[400px]"
            />
            <img
              src="/assets/image/com-tanloviet.webp"
              onDragStart={handleDragStart}
              role="presentation"
              alt="tanloviet"
              className="w-full max-w-[400px]"
            />
            <img
              src="/assets/image/com-tanviet.webp"
              onDragStart={handleDragStart}
              role="presentation"
              alt="tanviet"
              className="w-full max-w-[400px]"
            />
            {/* <AliceCarousel mouseTracking items={items} autoWidth autoPlay /> */}
          </div>
        </div>
        <div className="text-white text-3xl font-bold mt-20">
          <div className="text-center">{t("Our Customers")}</div>
          <div className="mt-10 self-center gap-10 flex flex-wrap mx-center text-center justify-center partner">
            <img
              src="/assets/image/com-honda.webp"
              onDragStart={handleDragStart}
              role="presentation"
              alt="iki"
              className="w-full max-w-[400px]"
            />
            <img
              src="/assets/image/com-abb.webp"
              onDragStart={handleDragStart}
              role="presentation"
              alt="tanloviet"
              className="w-full max-w-[400px]"
            />
            <img
              src="/assets/image/com-hitachi.webp"
              onDragStart={handleDragStart}
              role="presentation"
              alt="tanviet"
              className="w-full max-w-[400px]"
            />
            {/* <AliceCarousel mouseTracking items={items} autoWidth autoPlay /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
