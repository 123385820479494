import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Select } from "semantic-ui-react";
const products = require("../../src/data/product.json");

export const Section2 = () => {
  const { t } = useTranslation();
  const productsData = _.cloneDeep(products);
  const [currentCate, setCategory] = useState(0);
  const [currentData, setCurrentData] = useState(productsData[0]);

  useEffect(() => {
    setCurrentData(productsData[currentCate]);
  }, [currentCate]);

  return (
    <div className="w-screen h-full relative bg-white">
      <div className="section-container py-20 relative">
        <div className="sm:flex justify-between">
          <div className="font-bold text-3xl uppercase mb-10">
            {t("our products")}
          </div>
          <div>
            <Select
              placeholder={"Select category"}
              fluid
              value={currentCate}
              options={[0, 1, 2, 3].map((i) => {
                return { text: t(productsData[i].category), value: i };
              })}
              onChange={(evt, val) => {
                setCategory(val.value);
              }}
            />
          </div>
        </div>
        {currentData && (
          <>
            {/* {
              <Gallery
                margin={4}
                images={currentData.product?.map((i) => {
                  return {
                    ...i,
                    src: `/assets/product/${i.image}`,
                  };
                })}
              />
            } */}

            {currentData.product.length % 3 === 0 ? (
              <div className="flex flex-wrap md:grid md:grid-cols-4 justify-center md:justify-between gap-10 w-full sm:mt-0 mt-10">
                {currentData.product?.map((i, idx) => {
                  return (
                    <div
                      key={`product-${idx}`}
                      className={`bg-product-${currentCate} product-container`}
                    >
                      <img
                        src={`/assets/product/${i.image}`}
                        alt={"product"}
                        className="object-cover mx-auto"
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-wrap md:grid md:grid-cols-6 justify-center md:justify-between gap-10 w-full sm:mt-0 mt-10">
                {currentData.product?.map((i, idx) => {
                  return (
                    <div
                      key={`product-${idx}`}
                      className={`bg-product-${currentCate} product-container`}
                    >
                      <img
                        src={`/assets/product/${i.image}`}
                        alt={"product"}
                        className="object-cover mx-auto"
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
