import { useTranslation } from "react-i18next";

export const Section7 = () => {
  const { t } = useTranslation();
  return (
    <div className="w-screen h-full bg-white">
      <div className="md:flex justify-center md:gap-20 2xl:gap-60 section-container py-20 relative">
        <div className="md:mt-10">
          <div className="font-bold text-3xl">{t("Our Vision & Strategy")}</div>
          <div className="font-bold text-5xl mt-4">{t("We are")} HMC</div>
          <div className="mt-6 max-w-[500px]">
            <div className="mt-4">{t("vision_1")}</div>
            <div className="mt-4">{t("vision_2")}</div>
            <div className="mt-4">
              <div className="mt-4">{t("vision_3")}</div>
            </div>
            <div className="font-bold mt-4 text-lg">
              <div className="mt-4">{t("vision_4")}</div>
            </div>
          </div>
        </div>
        <div>
          <img
            src="/assets/image/ceo.webp"
            alt="ceo"
            className="mt-10 md:mt-0 md:h-[600px] object-contain"
          />
        </div>
      </div>
    </div>
  );
};
