import { useEffect, useState } from "react";

export const Section4 = ({ language }) => {
  const [src, setSrc] = useState("/assets/image/mission.webp");
  const allowedLanguage = ["vi", "en"];

  useEffect(() => {
    let prefix = "";
    if (language && allowedLanguage.includes(language)) {
      prefix = `-${language}`;
    }
    setSrc(`/assets/image/mission${prefix}.webp`);
  }, [language]);

  return (
    <div className="w-screen h-full bg-white section-container py-20">
      <div className="text-3xl font-bold">HMC VIETNAM</div>
      <div>
        <img
          src={src}
          alt="mission"
          className="mt-10 w-full sm:w-3/5 mx-auto text-center"
        />
      </div>
    </div>
  );
};
